import { apiBaseQuery } from '../apiBaseQuery';
import { SERVICE_URL } from '@components/constants/constants';
import { IGroups } from 'interfaces/groups.interfaces';

export interface ResponseGroups {
  content: IGroups[];
}

export const groupApi = apiBaseQuery.injectEndpoints({
  endpoints: (builder) => ({
    getGroupsList: builder.query({
      query: (params) => ({
        url: `${SERVICE_URL}/groups`,
        params,
      }),

      providesTags: ['Groups'],
      transformResponse: (response: ResponseGroups) => {
        return response.content;
      },
    }),

    getParticipantsList: builder.query<any, Record<string, string>>({
      query: ({ groupId, params }) => ({
        url: `${SERVICE_URL}/groups/${groupId}/participants-page?${params}`,
      }),
      transformResponse: (response: any) => {
        return response.content;
      },
    }),

    createGroup: builder.mutation<void, any>({
      query: (user) => ({
        body: user,
        url: `${SERVICE_URL}/groups`,
        method: 'POST',
      }),
      invalidatesTags: ['Groups'],
    }),

    updateGroupName: builder.mutation<void, any>({
      query: ({ id, params }) => ({
        url: `${SERVICE_URL}/groups/${id}/name/${params}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Groups'],
    }),

    deleteGroup: builder.mutation<void, string>({
      query: (id) => ({
        url: `${SERVICE_URL}/groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Groups'],
    }),

    updateParticipantsGroup: builder.mutation<void, any>({
      query: ({ id, data }) => ({
        body: data,
        url: `${SERVICE_URL}/groups/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Groups'],
    }),

    updateParticipantsAccessGroup: builder.mutation<void, any>({
      query: ({ id, data }) => ({
        body: data,
        url: `${SERVICE_URL}/groups/${id}/permissions`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Groups'],
    }),
  }),
});

export const {
  useGetGroupsListQuery,
  useCreateGroupMutation,
  useUpdateGroupNameMutation,
  useDeleteGroupMutation,
  useUpdateParticipantsGroupMutation,
  useUpdateParticipantsAccessGroupMutation,
  useGetParticipantsListQuery,
} = groupApi;
